import _export from "../internals/export";
import _descriptors from "../internals/descriptors";
import _ownKeys from "../internals/own-keys";
import _toIndexedObject from "../internals/to-indexed-object";
import _objectGetOwnPropertyDescriptor from "../internals/object-get-own-property-descriptor";
import _createProperty from "../internals/create-property";
var $ = _export;
var DESCRIPTORS = _descriptors;
var ownKeys = _ownKeys;
var toIndexedObject = _toIndexedObject;
var getOwnPropertyDescriptorModule = _objectGetOwnPropertyDescriptor;
var createProperty = _createProperty;

// `Object.getOwnPropertyDescriptors` method
// https://tc39.es/ecma262/#sec-object.getownpropertydescriptors
$({
  target: "Object",
  stat: true,
  sham: !DESCRIPTORS
}, {
  getOwnPropertyDescriptors: function getOwnPropertyDescriptors(object) {
    var O = toIndexedObject(object);
    var getOwnPropertyDescriptor = getOwnPropertyDescriptorModule.f;
    var keys = ownKeys(O);
    var result = {};
    var index = 0;
    var key, descriptor;
    while (keys.length > index) {
      descriptor = getOwnPropertyDescriptor(O, key = keys[index++]);
      if (descriptor !== undefined) createProperty(result, key, descriptor);
    }
    return result;
  }
});
export default {};